.scratchToWin {
  padding-top: 3.2rem;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 622px;

  @media (max-width: 1259px) {
    padding-top: 2.5rem;
    background-size: auto 586px;
  }

  @media (max-width: 1199px) {
    padding-top: 1.5rem;
    background-size: auto 784px;
  }

  @media (max-width: 991px) {
    padding-top: 1rem;
    background-size: auto 580px;
  }

  @media (max-width: 767px) {
    padding-top: 47rem;
    background-size: auto 1070px;
  }

  @media (max-width: 575px) {
    padding-top: 115%;
    background-size: calc(110% + -20px);
  }
}

.scratchToWin-topBack {
  display: inline-flex;
  gap: 0.8em;
  align-items: center;
  margin-bottom: 20rem;
  padding: 1em;
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  font-family: 'Libre Franklin';
  line-height: 1;
  background: rgba(0, 0, 0, 0.5);

  span {
    font-size: 15px;
    transform: rotate(180deg);
  }

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 1259px) {
    margin-bottom: 18.5rem;
  }

  @media (max-width: 1199px) {
    margin-bottom: 29rem;
  }

  @media (max-width: 991px) {
    margin-bottom: 19rem;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.scratchToWin-captcha {
  display: inline-block;
  min-height: 90px;
  vertical-align: top;

  .publicForm-captcha { margin: 0; }
}

.scratchToWin-fieldsAndSubmitWrapper {
  @media (min-width: 768px) {
    display: flex;
    gap: 0.4em;
    align-items: flex-start;
  }
}

.scratchToWin-fields {
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .fakePlaceholder {
    left: 26px;
    color: #fff;
    font-weight: 500;
    font-family: 'Libre Franklin';
    letter-spacing: 0.6px;
    text-transform: none;

    &:not(.fakePlaceholder-minify) {
      top: 12px;
      font-size: 16px;
    }
  }
}

.scratchToWin-newsletter {
  display: flex;
  gap: 1em;
  align-items: center;
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  font-family: 'Libre Franklin';

  input {
    width: 26px;
    height: 26px;
  }
}

.scratchToWin-emailInput {
  min-width: 380px;
  padding-right: 25px;
  padding-left: 25px;
  color: #fff;
  border: 0;

  &:not(textarea) { height: 50px !important; }

  &:focus {
    color: #fff;
    box-shadow: none;
  }

  @media (max-width: 767px) {
    min-width: 0;
  }
}

.scratchToWin-formSubmitButton {
  height: 50px;
  padding: 0 3.3em;
  background: #363636;
  transition: background 0.3s;

  &:before {
    right: 67%;
    background: #5e5e5e;
  }

  &:not(.mercierLoading):hover:before { right: 67%; }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.scratchToWin-form {
  .error,
  .additional_errors { color: #ff2222; }
}

.scratchToWinCard {
  display: none;
  width: max-content;
  padding: 20px;
  border: 3.5px solid #fff;
  border-radius: 13px;

  @media (max-width: 767px) {
    padding: 16px;
    border-width: 3px;
    border-radius: 10px;
  }
}

.scratchToWinCard-scratchableArea {
  overflow: hidden;
  background: #fff3e3;
  border-radius: 7px;

  @media (max-width: 767px) {
    border-radius: 5px;
  }
}

.scratchToWinCard-scratchableArea-scratched { cursor: default !important; }

.scratchToWinCard-scratchedResult {
  text-align: center;
  user-select: none;
}

.scratchToWinCard-title {
  margin-bottom: 0;
  padding-top: 2.3em;
  font-weight: 900;
  font-size: 20px;
  font-family: 'Libre Franklin';
  line-height: 1.3;

  @media (max-width: 767px) {
    margin-bottom: 0.8em;
    padding-top: 2.2em;
    font-size: 16px;
  }
}

.scratchToWinCard-prize {
  font-weight: 900;
  font-size: 110px;
  font-family: 'Libre Franklin';
  line-height: 1;

  @media (max-width: 767px) {
    font-size: 67px;
  }
}

.scratchToWinCard-codeTitle {
  margin-top: 0.4em;
  margin-bottom: 0;
  font-weight: 900;
  font-size: 20px;
  font-family: 'Libre Franklin';
  line-height: 1.3;

  @media (max-width: 767px) {
    margin-top: 1em;
    font-size: 16px;
  }
}

.scratchToWinCard-codeAndTooltipWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 0.2em;
  padding-left: 35px;

  @media (max-width: 767px) {
    padding-left: 31px;
  }
}

.scratchToWinCard-code {
  color: #000;
  font-weight: 900;
  font-size: 30px;
  font-family: 'Libre Franklin';
  line-height: 1.3;

  @media (max-width: 767px) {
    font-size: 24px;
  }
}

.scratchToWinCard-tooltip { position: relative; }

.scratchToWinCard-tooltipIcon {
  width: 25px;
  cursor: pointer;

  @media (max-width: 767px) {
    width: 21px;
  }
}

.scratchToWinCard-tooltipText {
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: max-content;
  margin-bottom: 10px;
  padding: 1em;
  font-weight: 600;
  font-size: 14px;
  font-family: 'Libre Franklin';
  line-height: 1;
  background: #fff;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(50, 50, 50, 0.5);
  transform: translateX(-50%);
  opacity: 0;

  .scratchToWinCard-tooltipIcon:hover + & { opacity: 1; }

  @media (max-width: 767px) {
    right: 0;
    left: auto;
    transform: none;
  }
}

.scratchToWinCard-bottomText {
  max-width: 56%;
  margin: 1em auto 0;
  font-weight: 500;
  font-size: 12px;
  font-family: 'Libre Franklin';
  line-height: 1.3;

  @media (max-width: 767px) {
    max-width: 95%;
    font-size: 10px;
  }
}

.promotion-scratchToWinPage {
  .promotion-texts {
    margin-top: 8rem;
  }
}
